import * as React from "react";
import { loginPageLoginForm, loginWrapper } from "./LoginPage.module.scss";
export const LoginPageLoginForm = (props: any) => {
  return (
    <div className={`${loginPageLoginForm} loginPage`}>{props.children}</div>
  );
};
export const LoginWrapper = (props: any) => {
  return <div className={loginWrapper}>{props.children}</div>;
};
