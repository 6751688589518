import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import {
  LoginPageLoginForm,
  LoginWrapper,
} from "../pageHelpers/LoginPage/LoginPageHelpers";
import { clearMember } from "../redux/slices/Member/member";
import Subtitle from "../components/global/SubTitle/SubTitle";
import { Title1 } from "../components/common/LoginForm/LoginFormHelpers";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import LoginForm from "../components/common/LoginForm/LoginForm";
// import SocialMedia from "../components/SocialMedia";
import * as auth0Client from "../utils/auth0Client";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ILoginPageProps } from "../pageHelpers/LoginPage/LoginPageProps";

const LoginPage = (props: ILoginPageProps) => {
  const dispatch = useAppDispatch();
  const member = useAppSelector((state) => state.member);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    (async () => {
      const authenticated = auth0Client.isAuthenticated();
      if (authenticated && member.isLoggedIn) {
        //check if user coming from contact page
        const isRedirectToContactUs = localStorage.getItem("contactUsRedirect")
          ? JSON.parse(localStorage.getItem("contactUsRedirect") || "")
          : false;
        if (isRedirectToContactUs) {
          localStorage.removeItem("contactUsRedirect");
          navigate("/contact");
        } else navigate("/account", { state: { isAuthenticated: true } });
      } else {
        await dispatch(clearMember());
        setIsloading(false);
      }
    })();
  }, [member.isLoggedIn]);

  return (
    <Layout>
      <SEO title={`Login`} />
      <Container fluid="sm" className="pt-5 mb-5 p-5">
        <LoginWrapper>
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <Subtitle>TRAVEL PASS</Subtitle>
              <Title1>Sign In</Title1>
              <LoginPageLoginForm>
                <LoginForm displayWelcome={`none`} location={props.location} />
              </LoginPageLoginForm>
            </>
          )}
        </LoginWrapper>
      </Container>
    </Layout>
  );
};

export default LoginPage;
